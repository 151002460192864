/* App.css */

/* Ensure padding doesn't increase the width */
*, *::before, *::after {
  box-sizing: border-box;
}

.XperienceContainer {
  box-sizing: border-box; /* This ensures padding and borders are included in the element's total width and height */
  position: fixed; /* Use fixed instead of absolute to keep it in viewport */
  top: 0;
  right: 0; /* Position it on the right side */
  display: flex;
  flex-direction: column;
  left: auto; /* Start with the pane on the right */
  align-items: center;
  width: 30%; /* Adjust this to the desired width of your pane */
  /* height: 100%; */
  z-index: 1000;
  /* overflow-y: scroll; Allow vertical scrolling */
  overflow-x: hidden; /* Prevent horizontal scrolling */
}

/* And so on for other AntD components */

.ant-layout-content {
  flex: 1 0 auto;
  margin: 0; /* This allows the content to grow but not shrink, pushing the footer down */
}

.ant-card {
  background-color: transparent;
}

.ant-layout-footer {
  flex-shrink: 0; /* This prevents the footer from shrinking */
}

.ant-layout-header {
  margin: 0; 
  padding: 0;
  width: 100%;
  background-color: #f5f5f5;
  /* Add any other styles you want for the header */
}

.ant-layout-footer {
  margin-top: auto; /* Remove padding */
  width: 100%;
  /* background-color: #f5f5f5;
  color: #222222; */
  /* Add any other styles you want for the footer */
}

.toggle-container {
  display: flex;
  align-items: center;
  margin-bottom: 20px; /* Spacing between the toggle and the table */
}

.toggle-container span {
  margin: 0 10px; /* Spacing between the switch and the labels */
  font-size: 12px; /* Adjust as needed */
  font-weight: 500; /* Adjust as needed */
}

/* Adjust positioning for larger screens */
@media (min-width: 990px) {
  .XperienceContainer {
    width: 30%; /* Adjust to your desired width */
    right: 0;
  }
}

/* Adjust positioning for mobile screens */
@media (max-width: 989px) {
  .XperienceContainer{
    width: 100%;
    right: auto;
    left: 0;
  }
}

.dropzone {
  padding: 20px;
  border: 2px dashed #444;
  border-radius: 5px;
  text-align: center;
  cursor: pointer;
  transition: border-color 0.3s;
}

.dropzone:hover {
  border-color: #555;
}

canvas{
  width: 100%;
  height: 100%;
}

.preview-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  margin-top: 5px;
  background-color: #383838;
  border: #555;
  border-style: dashed;
  padding: 1%;
}

.column1 {
  flex: 2;
  padding-left: 7px;
}
.column2 {
  flex: 2;
  padding: 7px;
}

.thumb {
  position: relative;
  width: 100%;
  height: 150px;
  overflow: hidden;
  border-radius: 5px;
}

.thumb-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 5px;
}


.pwa-footer {
  margin-top: auto;
  position: absolute;
  bottom: 0;
}

.error-message {
  color: #ff4b4b;
  margin-top: 10px;
}


